import * as React from 'react'
import withHydrationOnDemand from 'react-hydration-on-demand'
import dynamic from 'next/dynamic'

import { ContentItem, ProductBlock } from '@thg-commerce/enterprise-components'
import { WishlistButtonType } from '@thg-commerce/enterprise-components/Product/src/WishlistButton/WishlistButton'
import { useCustomer } from '@thg-commerce/enterprise-core/src/CustomerContext'
import {
  ColourDataLayerGA4,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import { PRODUCT_CONTENT_KEY } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Product'
import {
  FreeTextProductPersonalisationField,
  SingleSelectionProductPersonalisationField,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import { CallbackSetEvent } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import {
  InStockLocation,
  ProductMarketedSpecialOffer,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Beacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'
import { WishlistButtonStyleInterface } from '@thg-commerce/enterprise-pages/src/Product/theme'
import { SelectedOptions } from '@thg-commerce/enterprise-product-options/src'
import { spacing, styled } from '@thg-commerce/enterprise-theme'
import { ProductPriceProps } from '@thg-commerce/gravity-elements'
import {
  SwatchShape,
  SwatchSize,
} from '@thg-commerce/gravity-elements/Swatch/types'
import { ProductBlockSkeleton } from '@thg-commerce/gravity-patterns'
import { MarketedSpecialOfferSummaryContainerProps } from '@thg-commerce/gravity-patterns/MarketedSpecialOfferSummary/MarketedSpecialOfferSummaryContainer'
import { PictureProps } from '@thg-commerce/gravity-system'
import { BreakpointArray } from '@thg-commerce/gravity-theme'

import { PapBadgeStyle, QubitBadgeStyle } from '../theme'

export type ProductListMarketedSpecialOfferProps = Omit<
  MarketedSpecialOfferSummaryContainerProps,
  'i18nText' | 'loading' | 'title' | 'description'
> & {
  title: ProductMarketedSpecialOffer['title']
  description: ProductMarketedSpecialOffer['description']
  titleText: string
  descriptionText: string
}

export interface ProductItemProps {
  sku: string
  url: string
  title: string
  inStock: boolean
  isCheckStock: boolean
  isOrderInStore: boolean
  preorder?: boolean
  leadTime?: number
  weightGroups?: string[]
  dataLayerBrand?: string
  isBookable?: boolean
  inStockLocations?: InStockLocation[]
  lazy?: boolean
  brand: {
    name: string
    imageUrl?: string
  }
  pricePerUnit?: {
    displayValue?: string
    unit?: string
  }
  price?: ProductPriceProps
  emitImpressionEvent?: (params: {
    logger: any
    eventType: CallbackSetEvent
    callbackData?: string
    callbackURL?: string
  }) => void
  images?: {
    thumbnail?: string
    largeProduct?: string
    zoom?: string
    tag?: string
  }[]
  picture?: PictureProps
  hoverPicture?: PictureProps
  pictures?: PictureProps[]
  icons?: {
    homeDelivery: boolean
    storeAvailable: boolean
    storeDelivery: boolean
  }
  reviews?: {
    total: number
    averageScore: number
  }
  shape: SwatchShape
  marketedSpecialOffer?: ProductListMarketedSpecialOfferProps
  colourSwatches?: {
    key: string
    colour: string
    title: string
    href: string
  }[]
  quickBuyFeatureEnabled?: boolean
  productI18nText: {
    buyAriaLabel: (variables: string | string[]) => string
    buyText: string
    personalisedBuyText: string
    quickBuyText: string
    preorderNowText: string
    soldOutText: string
    reviewsStarsLabel: string
    reviewsLabel: string
    closeI18nText: {
      closeAriaLabel: string
      closeLabel: string
    }
    fulfilmentMethodsText: {
      clickAndCollect: {
        isAvailable: string
        isNotAvailable: string
      }
      homeDelivery: {
        isAvailable: string
        isNotAvailable: string
        isOutOfStock: string
        datedDelivery: string
        nextDayDelivery: string
      }
      storeDelivery: {
        isAvailable: string
      }
      orderInStore: {
        isAvailable: string
        message?: string
      }
    }
    freeGiftMessage: string
    swatchUnavailableText: string
    swatchCloseButtonText: string
    swatchMoreText?: string
    sponsoredLabelText: string
  }
  wishlistI18nText: {
    addToWishlistText: string
    savedToWishlistText: string
    wishlistTooltip: {
      closeButtonText: string
    }
    wishlistTooltipContent: {
      loginSignupText: string
      toUseWishlistText: string
    }
  }
  siteConfig: {
    hideProductListReviewRating?: boolean
    hideProductListProductBlockButton?: boolean
    productListReviewRatingThreshold?: number
    showMarketedSpecialOfferIcon?: boolean
    hideProductListImageRollover?: boolean
    showProductListProductBlockIcons?: boolean
    hasClickAndCollect?: boolean
    showPdpLinkWhenOutOfStock?: boolean
    enableHorizontalFacets?: boolean
    showProductBlockBrandTitle?: boolean
    showProductBlockBrandLogo?: boolean
    hideProductListSwatch?: boolean
    hasImagesCarouselOnMobilePLP?: boolean
    useGA4EnhancedEcom?: boolean
    enableWishlists?: boolean
    useExternalIdentifier?: boolean
    useExternalIdentifierInSchema?: boolean
    enablePapOverlay?: boolean
    papProductItemHighlightedTitleList?: string[]
  }
  attributesInsetSpacing: BreakpointArray<number>
  wishlistButtonStyle: WishlistButtonStyleInterface
  externalIdentifier?: string
  qubitBadgeStyle?: QubitBadgeStyle
  papBadgeStyle?: PapBadgeStyle
  forwardedRef?: any
  inViewport?: boolean
  enterCount?: number
  index?: number
  sponsoredAdsBeacons?: {
    topLevel?: Beacon
    productLevel?: {
      onViewBeacon?: Beacon
      onWishlistBeacon?: Beacon
      onBasketChangeBeacon?: Beacon
      onClickBeacon?: Beacon
    }
  }
  isSponsored?: boolean
  mobileSingleColumn?: boolean
  content?: ContentItem[]
  listPageTitle?: string
  selectedOptions?: SelectedOptions
  personalisationFields?: [
    | FreeTextProductPersonalisationField
    | SingleSelectionProductPersonalisationField,
  ]
}

const DEFAULT_MIN_RATING_VALUE = 0

const productItemWrapper =
  typeof document !== 'undefined'
    ? document.querySelector("[data-testid='product-item-wrapper']")
    : null

const WishlistButtonComponent = dynamic(
  () =>
    import(
      '@thg-commerce/enterprise-components/Product/src/WishlistButton/WishlistButton'
    ),
)

const WishlistButton = withHydrationOnDemand({
  on: [
    ['mouseenter', () => productItemWrapper],
    ['focus', () => productItemWrapper],
    ['keydown', () => productItemWrapper],
  ],
})(WishlistButtonComponent)

export const WishlistContainer = styled.div`
  position: absolute;
  top: ${spacing(1)};
  right: calc(
    ${spacing(1)} +
      ${(props) => `${spacing(props.theme.widget.productList.gridGap / 2)}`}
  );
`

const getButtonData = (
  productProps: ProductItemProps,
  hideProductListProductBlockButton?: boolean,
  showPdpLinkWhenOutOfStock?: boolean,
) => {
  if (hideProductListProductBlockButton) {
    return undefined
  }

  if (productProps.inStock) {
    const buttonTitle = productProps.preorder
      ? {
          title: productProps.productI18nText.preorderNowText,
          quickBuyTitle: productProps.productI18nText.preorderNowText,
        }
      : {
          title: productProps.productI18nText.buyText,
          quickBuyTitle: productProps.productI18nText.quickBuyText,
        }
    return {
      title:
        productProps?.personalisationFields &&
        productProps.personalisationFields?.length > 0
          ? productProps.productI18nText.personalisedBuyText
          : buttonTitle.title,
      quickBuyTitle: buttonTitle.quickBuyTitle,
      ariaLabel: productProps.productI18nText.buyAriaLabel(productProps.title),
      productInStock: Boolean(productProps.inStock),
      mobileSingleColumn: productProps.mobileSingleColumn,
    }
  }

  return {
    title: productProps.productI18nText.soldOutText,
    quickBuyTitle: '',
    disabled: !showPdpLinkWhenOutOfStock,
    productInStock: Boolean(productProps.inStock),
  }
}

export const ProductItem = (props: ProductItemProps) => {
  const {
    hideProductListReviewRating,
    hideProductListProductBlockButton,
    productListReviewRatingThreshold,
    showMarketedSpecialOfferIcon,
    hideProductListImageRollover,
    hasClickAndCollect,
    showPdpLinkWhenOutOfStock,
    showProductBlockBrandTitle,
    showProductBlockBrandLogo,
    hideProductListSwatch,
    hasImagesCarouselOnMobilePLP,
    enableWishlists,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    useGA4EnhancedEcom,
    enablePapOverlay,
  } = props.siteConfig

  const { wishlist } = useCustomer()

  const inWishlist = wishlist?.skus.includes(parseInt(props.sku, 10))

  const screenReaderReviewText = ({ score, total }) =>
    `${score} ${props.productI18nText.reviewsStarsLabel}
  ${total} ${props.productI18nText.reviewsLabel}`

  const minRatingValue =
    productListReviewRatingThreshold ?? DEFAULT_MIN_RATING_VALUE

  const productAllImages = props.images?.map((image) => {
    return { isAmp: false, urls: image, lazy: props.lazy }
  })

  const sponsoredAdLabel = props.isSponsored
    ? props.productI18nText.sponsoredLabelText
    : undefined

  const shouldFireBeacons =
    props.isSponsored &&
    props.inViewport &&
    props.enterCount &&
    props.enterCount < 2

  if (shouldFireBeacons) {
    if (props.index === 0) {
      if (props.sponsoredAdsBeacons?.topLevel) {
        navigator.sendBeacon(props.sponsoredAdsBeacons?.topLevel.url)
      }
    }

    if (props.sponsoredAdsBeacons?.productLevel?.onViewBeacon) {
      navigator.sendBeacon(
        props.sponsoredAdsBeacons?.productLevel.onViewBeacon.url,
      )
    }
  }

  return (
    <div
      ref={props.forwardedRef}
      data-testid="product-item-wrapper"
      style={{ height: '100%' }}
    >
      <ProductBlock
        index={props.index}
        selectedOptions={props.selectedOptions}
        priceRange={props.priceRange}
        rrpRange={props.rrpRange}
        emitImpressionEvent={props.emitImpressionEvent}
        attributesInsetSpacing={props.attributesInsetSpacing}
        imagesCarousel={
          hasImagesCarouselOnMobilePLP && props.mobileSingleColumn
        }
        productVariant={props.productVariant}
        pricePerUnit={props.pricePerUnit}
        price={props.price}
        sku={props.sku}
        title={{ value: props.title }}
        brand={props.brand}
        url={props.url}
        label={sponsoredAdLabel}
        externalIdentifier={props.externalIdentifier}
        review={
          props.reviews &&
          !hideProductListReviewRating &&
          props.reviews.averageScore >= minRatingValue
            ? {
                starRating: props.reviews.averageScore,
                numberOfReviews: props.reviews.total,
                screenReaderOnlyText: screenReaderReviewText({
                  score: props.reviews.averageScore,
                  total: props.reviews.total,
                }),
              }
            : undefined
        }
        image={{
          isAmp: false,
          urls:
            props.images && props.images.length > 0
              ? {
                  largeProduct: props.images[0].largeProduct,
                  thumbnail: props.images[0].thumbnail,
                  tag: props.images[0].tag,
                }
              : {},
          lazy: props.lazy,
        }}
        pictures={props.pictures}
        productImages={productAllImages}
        picture={props.picture}
        hoverPicture={props.hoverPicture}
        marketedSpecialOffer={
          props.marketedSpecialOffer && {
            ...props.marketedSpecialOffer,
            title: props?.marketedSpecialOffer?.titleText,
            description: props?.marketedSpecialOffer?.descriptionText,
            loading: false,
            onlyDisplayOfferBadge: true,
            showIcon: showMarketedSpecialOfferIcon,
            i18nText: {
              closeI18nText: props.productI18nText.closeI18nText,
              freeGiftMessage: props.productI18nText.freeGiftMessage,
            },
          }
        }
        hoverImage={
          !hideProductListImageRollover &&
          props.images &&
          props.images.length > 1
            ? {
                isAmp: false,
                urls: {
                  largeProduct: props.images[1].largeProduct,
                  thumbnail: props.images[1].thumbnail,
                  tag: props.images[0].tag,
                },
                lazy: typeof window !== 'undefined' ? false : true,
                fetchPriority: 'low',
              }
            : undefined
        }
        button={getButtonData(
          props,
          hideProductListProductBlockButton,
          showPdpLinkWhenOutOfStock,
        )}
        fulfilmentMethodIconsProps={{
          hasClickAndCollect,
          isCheckStock: props.isCheckStock,
          isOrderInStore: props.isOrderInStore,
          inStock: props.inStock,
          iconsAvailability: props.icons,
          i18nText: props.productI18nText.fulfilmentMethodsText,
          leadTime: props.leadTime,
          weightGroups: props.weightGroups,
          isBookable: props.isBookable,
          inStockLocations: props.inStockLocations,
        }}
        swatch={
          props.colourSwatches
            ? {
                shape: props.shape,
                size: SwatchSize.Small,
                colours: props.colourSwatches,
                onColourChange: (value: string) => {
                  useGA4EnhancedEcom &&
                    pushToEventGA4<ColourDataLayerGA4>({
                      event: 'colour_select',
                      colour: value,
                      item_id:
                        (useExternalIdentifier ||
                          useExternalIdentifierInSchema) &&
                        props.externalIdentifier
                          ? props.externalIdentifier
                          : props.sku.toString(),
                      item_name: props.title,
                    })
                },
                i18nText: {
                  unavailableText: props.productI18nText.swatchUnavailableText,
                  closeButtonText: props.productI18nText.swatchCloseButtonText,
                  moreText: props.productI18nText.swatchMoreText,
                },
              }
            : undefined
        }
        showBrandTitle={showProductBlockBrandTitle}
        showBrandLogo={showProductBlockBrandLogo}
        hideProductListSwatch={hideProductListSwatch}
        qubitBadgeStyle={props.qubitBadgeStyle}
        papBadgeStyle={props.papBadgeStyle}
        content={props?.content}
        loopSlides={hasImagesCarouselOnMobilePLP}
        sponsoredAdsBeacons={{
          onBasketChangeBeacon:
            props.sponsoredAdsBeacons?.productLevel?.onBasketChangeBeacon,
          onClickBeacon: props.sponsoredAdsBeacons?.productLevel?.onClickBeacon,
        }}
        listPageTitle={props.listPageTitle}
        enablePapOverlay={enablePapOverlay}
      />
      {enableWishlists && (
        <WishlistContainer>
          <WishlistButton
            buttonType={WishlistButtonType.HEART}
            sku={parseInt(props.sku, 10)}
            inWishlist={inWishlist}
            i18nText={props.wishlistI18nText}
            buttonStyle={props.wishlistButtonStyle}
            title={props.title}
            price={props.price?.price.defaultPrice}
            onWishlistBeacon={
              props.sponsoredAdsBeacons?.productLevel?.onWishlistBeacon
            }
            externalIdentifier={props?.externalIdentifier}
            brandName={props?.brand?.name}
            dataLayerBrand={props.dataLayerBrand}
            itemCategories={props?.content}
            productVariant={props.productVariant}
          />
        </WishlistContainer>
      )}
    </div>
  )
}

export const ProductItemSkeleton = (count: number) => {
  const SkeletonBlocks = [...Array(count)].map((_, index) => (
    <div key={index} data-testid={`product-list-item-skeleton-${index}`}>
      <ProductBlockSkeleton />
    </div>
  ))
  return SkeletonBlocks
}

export default ProductItem
