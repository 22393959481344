import * as React from 'react'
import dynamic from 'next/dynamic'

import {
  css,
  opacity,
  spacing,
  styled,
  margin,
  mq,
} from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'
import { ButtonState } from '@thg-commerce/gravity-elements/Button'
import {
  WishlistButtonPDPStyle,
  WishlistButtonStyleInterface,
} from '@thg-commerce/enterprise-pages/src/Product/theme'

import { WishlistButtonType } from './WishlistButton'

const HeartIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/WishlistSelected'),
  { loading: () => <div style={{ width: 24, height: 24 }} /> },
)

const buttonStateProperties = (state: ButtonState) => (props) => css<{
  inWishlist?: boolean
}>`
  background-color: ${opacity(
    props.buttonStyle[state].button.backgroundColor,
    props.buttonStyle[state].button.backgroundOpacity,
  )};
  border-color: ${props.buttonStyle[state].button.borderColor};

  svg {
    ${(props.inWishlist &&
      [ButtonState.Active, ButtonState.Focus].includes(state)) ||
    state === ButtonState.Hover
      ? css`
          fill: ${props.buttonStyle[state].icon.fill};
          fill-opacity: ${props.buttonStyle[state].icon.opacity};
        `
      : css`
          fill: ${props.buttonStyle.default.icon.fill};
          fill-opacity: ${props.buttonStyle.default.icon.opacity};
        `}
    color: ${props.buttonStyle[state].icon.color};
  }
`

const customStateStyle = css`
  ${buttonStateProperties(ButtonState.Default)}

  &:hover {
    ${buttonStateProperties(ButtonState.Hover)}
  }

  &:focus {
    ${buttonStateProperties(ButtonState.Focus)}
  }

  &:active {
    ${buttonStateProperties(ButtonState.Active)}
  }
`

const buttonProperties = css<{
  selected?: boolean
  smallButton?: boolean
}>`
  ${(props) =>
    !props.smallButton
      ? css`
          border-radius: 50%;
          border-width: 2px;
          width: 48px;
          height: 48px;
        `
      : css`
          border-radius: 0;
        `}

  svg {
    fill: ${(props) =>
      props.selected ? 'currentColor' : props.theme.colors.palette.greys.white};
  }
`

const iconProperties = css<{
  inWishlist?: boolean
  buttonStyle?: WishlistButtonStyleInterface
}>`
  svg {
    fill: ${(props) =>
      props.inWishlist
        ? props.buttonStyle?.focus.icon.fill || 'currentColor'
        : props.theme.colors.palette.greys.white};
    fill-opacity: ${(props) =>
      props.inWishlist
        ? props.buttonStyle?.focus.icon.opacity ?? 1
        : props.buttonStyle?.default.icon.opacity ?? 1};
  }
`

const smallButtonProperties = css`
  height: 48px;
  width: 48px;
  min-width: 48px;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.palette.brand.base};
  margin: 0 0 0 ${spacing(1)};
`

const heartButtonProperties = css<{
  buttonStyle?: WishlistButtonStyleInterface
  inWishlist?: boolean
}>`
  ${buttonProperties}
  &:hover {
    background-color: ${(props) => props.theme.colors.palette.brand.lightest};
    ${buttonProperties}
  }

  &:focus,
  &:active {
    background-color: ${(props) => props.theme.colors.palette.brand.lighter};
    ${buttonProperties}
  }
`

const textButtonProperties = css<{ inWishlist?: boolean }>`
  svg {
    fill: ${(props) => props.theme.colors.palette.greys.white};
  }

  &:hover svg,
  &:active svg,
  &:focus svg {
    fill: ${(props) =>
      props.inWishlist
        ? 'currentColor'
        : props.theme.colors.palette.greys.white};
  }
`

export const WishlistIcon = styled(HeartIcon)<{
  heartButton?: boolean
  margin?: number
}>`
  display: block;
  stroke: currentColor;
  stroke-width: 2px;

  ${(props) =>
    props.heartButton
      ? css`
          width: 24px;
          height: 24px;
        `
      : css`
          width: 20px;
          height: 20px;
          margin: 0 ${spacing(1)} 0 ${spacing(-0.5)};
        `}

  ${(props) => props.margin && `margin: ${spacing(props.margin)}`}
`

export const WishlistContentContainer = styled.div`
  display: flex;
  align-items: center;
  outline: none;
`

export const StyledButton = styled(Button)<{
  buttonType: WishlistButtonType
  inWishlist?: boolean
  buttonStyle?: WishlistButtonStyleInterface
  wishlistPDPButtonStyle?: WishlistButtonPDPStyle
}>`
  ${(props) =>
    props.buttonType === WishlistButtonType.SMALL
      ? smallButtonProperties
      : props.buttonType === WishlistButtonType.HEART
      ? heartButtonProperties
      : textButtonProperties}
  ${(props) => props.buttonStyle && customStateStyle}
  ${iconProperties}
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${(props) =>
      props.wishlistPDPButtonStyle?.margin &&
      margin(props.wishlistPDPButtonStyle.margin)}
  }
}
`
