import * as React from 'react'

import { Routes } from '@thg-commerce/enterprise-core'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { styled, Text } from '@thg-commerce/enterprise-theme'

export const WishlistTooltipContentLink = styled.a`
  ${Text('xsmall', 'alternate')};
  color: ${({ theme }) => theme.colors.palette.brand.base};
`

export const WishlistTooltipContentText = styled.p`
  ${Text('xsmall', 'alternate')};
  color: ${(props) => props.theme.colors.palette.greys.darker};
`

export interface WishlistTooltipContentProps {
  sku: number
  i18nText: {
    loginSignupText: string
    toUseWishlistText: string
  }
}

export const WishlistTooltipContent = (props: WishlistTooltipContentProps) => {
  const toolTipLinkRef = React.useRef<HTMLAnchorElement>(null)

  React.useEffect(() => {
    toolTipLinkRef.current?.focus()
  }, [])

  if (typeof window === 'undefined') {
    return null
  }

  const currentURL = new URL(window.location.href)
  currentURL.searchParams.set('addSKUToWishlist', props.sku.toString())
  const loginURL = `${Routes.Login}?returnTo=${encodeURIComponent(
    currentURL.toString(),
  )}`

  const trackClickEvent = () => {
    pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'clicked login link',
        eventCategory: 'Wishlist Engagement',
        eventLabel: `${props.sku}`,
      },
    })
  }

  return (
    <React.Fragment>
      <WishlistTooltipContentText>
        <WishlistTooltipContentLink
          href={loginURL}
          onClick={trackClickEvent}
          ref={toolTipLinkRef}
        >
          {props.i18nText.loginSignupText}
        </WishlistTooltipContentLink>{' '}
        {props.i18nText.toUseWishlistText}
      </WishlistTooltipContentText>
    </React.Fragment>
  )
}
